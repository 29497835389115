import { config } from './config'

export const url = (endpoint: string, params: any = {}): string => {
    let url = config.baseUrl + endpoint;
    let index = 0;

    for (const key in params) {
        if (params[key].toString() === '') {
            continue;
        }

        if (index === 0) {
            url += '?' + key + '=' + params[key]
        } else {
            url += '&' + key + '=' + params[key]
        }

        index++
    }

    return url;
}
