













import { Http } from '../../classes/Http'
import Loader from '../../components/Loader.vue'

import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

interface Url {
  key: number;
  link: string;
}

@Component({
  components: { Loader }
})
export default class HttpGet extends Vue {
  @Prop({ default: '' }) url!: string | Array<Url>
  @Prop({ default: false }) smallLoader!: boolean

  data: Array<Url> = [];
  times = 0;
  isFetching = true;
  throttle = 0
  http = new Http({
    success: () => this.$emit('success', ''),
    unauthorized: () => {
      window.location.href = '/login'
    }
  })

  mounted () {
    this.fetch()
  }

  fetch () {
    this.data = []

    if (!Array.isArray(this.url)) {
      return this.http.fetch(this.url)
        .then((data: any) => {
          this.data = data
          this.isFetching = false
        })
    }

    for (const url of this.url) {
      return this.http.fetch(url.link)
        .then((data: any) => {
          this.data[url.key] = data
        })
    }
  }

  @Watch('url')
  throttleFetch () {
    clearTimeout(this.throttle)
    this.throttle = setTimeout(() => this.fetch(), 500)
  }

  @Watch('times')
  watchTimes () {
    if (this.url === undefined) {
      return
    }

    if (this.times === this.url.length) {
      this.isFetching = false
    }
  }
}
