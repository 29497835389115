










import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Card extends Vue {
  @Prop({ default: true }) padding!: boolean

  changed = false;

  handle () {
    this.changed = true
  }
}
