<template>
  <div class="fixed z-50 left-0 top-0 justify-center items-center w-screen h-screen bg-black-transparent">
    <div class="flex justify-center items-center w-screen h-screen">
      <div class="w-1/3 m-auto p-12">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {

}
</script>
