



import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Badge extends Vue {
  @Prop() label!: string
  @Prop() variant!: string

  get computedVariant () {
    switch (this.variant) {
      case 'danger':
        return 'bg-red'
      case 'success':
        return 'bg-green text-green-dark'
      default:
        return 'bg-black text-white'
    }
  }
}
