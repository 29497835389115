<template>
  <Sidebar :open="false">
    <template v-slot:nav>
<!--      <SidebarItem to="/voices" icon="mic" label="Voice Overs"></SidebarItem>-->
    </template>
    <template v-slot:content>
      <div class="flex justify-between py-6">
        <h1 class="font-bold text-2xl py-2 text-secondary">{{ $route.name }}</h1>

        <router-link to="/upload">
          <Button label="Upload" />
        </router-link>

        <HttpPost :url="url('/logout')" @fetch="logout">
          <Button variant="ghost" label="Logout" />
        </HttpPost>
      </div>
      <slot></slot>
    </template>
  </Sidebar>
</template>
<script>

import { Component, Vue } from 'vue-property-decorator'
import Navbar from "@/mave-ui/components/Navbar";
import SidebarItem from "@/mave-ui/components/ui/sidebar/SidebarItem"
import Sidebar from "@/mave-ui/components/ui/sidebar/Sidebar"
import Button from "@/mave-ui/components/form/Button";
import HttpPost from "@/mave-ui/components/logic/HttpPost";
import {Functions} from "@/mixins/Functions";

@Component({
  components: {HttpPost, Button, Navbar, SidebarItem, Sidebar},
  mixins: [Functions]
})
export default class BaseLayout extends Vue {
  logout() {
    window.location.href = '/login'
  }
}
</script>
