






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Searchbox extends Vue {
  @Prop() label!: string
  @Prop() value!: string
  @Prop({ default: false }) dark!: string
}
