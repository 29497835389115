

























import { Component, Vue, Prop } from 'vue-property-decorator'
import Card from '../Card.vue'
import DataTableColumn from './DataTableColumn.vue'
import Paginator from './Paginator.vue'
import Result from '@/mave-ui/interfaces/Result'

@Component({
  components: {
    DataTableColumn,
    Card,
    Paginator
  }
})
export default class DataTable extends Vue {
  @Prop() headers!: Array<string>
  @Prop() result!: Result
  @Prop() pagination!: Array<object>

  sortedHead = 'date'

  sortBy (head: string) {
    this.$emit('sort', head)
    this.sortedHead = head
  }
}
