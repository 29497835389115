


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Sidebar extends Vue {
  @Prop() logoPath!: string
  @Prop({ default: true }) open!: boolean;

  isOpen = this.open;

  mounted () {
    (window.innerWidth <= 640) ? this.isOpen = false : this.isOpen = this.open
  }
}
