






import {Vue, Component, Prop} from 'vue-property-decorator'
import Badge from "@/mave-ui/components/Badge.vue";
import HttpPost from "@/mave-ui/components/logic/HttpPost.vue";

@Component({
  components: {HttpPost, Badge}
})
export default class StatusChange extends Vue {
  @Prop() id!: number;
  @Prop() status!: number;

  currentStatus: number = this.status

  body = {
    status: (this.currentStatus === 0) ? 1 : 0
  };

  badge = {
    label: this.currentStatus === 0 ? 'New' : 'Done',
    variant: this.currentStatus === 0 ? '' : 'success'
  };

  changeStatus(data: any) {
    if (data.status !== 200) {
      this.badge = {label: 'Failed', variant: 'danger'}
    }

    this.currentStatus = (this.currentStatus === 0) ? 1 : 0
    this.body.status = (this.currentStatus === 0) ? 1 : 0

    this.badge = {
      label: this.currentStatus === 0 ? 'New' : 'Done',
      variant: this.currentStatus === 0 ? '' : 'success'
    }

    this.$forceUpdate()
  }

}
