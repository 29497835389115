<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'
import BaseLayout from './layouts/BaseLayout'

@Component({
  components: { BaseLayout }
})
export default class App extends Vue {

}
</script>
