






import { Vue, Component, Prop } from 'vue-property-decorator'
import { Option } from '../../interfaces/Option'

@Component
export default class Dropdown extends Vue {
  @Prop() options!: Array<Option>
  @Prop({ default: false }) dark!: boolean
}
