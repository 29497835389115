







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SidebarItem extends Vue {
    @Prop() to!: string;
    @Prop() icon!: string;
    @Prop() label!: string;
}
