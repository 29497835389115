<template>
  <td class="p-4"><slot></slot></td>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DataTableColumn extends Vue {

}
</script>
