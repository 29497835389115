











import { Component, Vue, Prop } from 'vue-property-decorator'
import Button from './form/Button.vue'

@Component({
  components: { Button }
})
export default class Navbar extends Vue {
  @Prop() heading!: string;

  get title (): string {
    return this.heading
  }
}
