













import { Component, Prop, Vue } from 'vue-property-decorator'
import LoaderIcon from '../svg/LoaderIcon.vue'

@Component({
  components: { LoaderIcon }
})
export default class Button extends Vue {
  @Prop() label!: string;
  @Prop({ default: 'primary' }) variant!: string;
  @Prop({ default: false }) loading!: boolean;

  get type (): Array<string> {
    switch (this.variant) {
      case 'primary':
        return ['bg-primary', 'text-white', 'hover:bg-primary-light', 'active:bg-primary-dark', 'shadow', 'shadow-lg']
      case 'danger':
        return ['bg-red', 'text-white', 'hover:bg-red-dark']
      case 'secondary':
        return ['bg-secondary-light', 'text-secondary', 'hover:bg-secondary-lightest', 'active:text-white', 'active:bg-secondary-lighter']
      default:
        return ['hover:bg-gray-light']
    }
  }
}
