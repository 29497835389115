<template>
  <svg class="animate-spin" width="100%" height="100%" viewBox="0 0 92 92" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <circle cx="46" cy="46" r="43" stroke="#D3E3E8" stroke-width="6"/>
    <path
        d="M46 89C38.1059 89 30.3641 86.8269 23.6231 82.7188C16.8821 78.6108 11.402 72.7264 7.78351 65.7104C4.16499 58.6945 2.54766 50.8178 3.10876 42.9436C3.66986 35.0695 6.38773 27.5017 10.9645 21.0698C15.5413 14.6378 21.8004 9.58989 29.0558 6.4792C36.3112 3.36851 44.2829 2.31512 52.0973 3.43448C59.9116 4.55384 67.267 7.80274 73.3574 12.8251C79.4478 17.8475 84.0381 24.4496 86.6252 31.9077"
        stroke="#1C7F9D" stroke-width="6"/>
  </svg>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LoaderIcon extends Vue {
}
</script>
