export interface HttpCallbacks {
    success: Function;
    unauthorized: Function;
}

export class Http {
    callback: HttpCallbacks

    constructor (callback: HttpCallbacks) {
      this.callback = callback
    }

    response (res: Response) {
      switch (res.status) {
        case 200:
          this.callback.success()
          return res.json()
        case 401:
          return this.callback.unauthorized()
      }
    }

    fetch (endpoint: string) {
      // Url building should come here in the future

      return fetch(endpoint)
        .then(res => this.response(res))
    }
}
