





import { Component, Prop, Vue } from 'vue-property-decorator'

declare let window: any

@Component
export default class HttpPost extends Vue {
  @Prop({ default: '' }) url!: string;
  @Prop() body!: FormData

  static SUCCESS = 1;
  static FAILED = 0;

  isFetching = false;
  status = HttpPost.SUCCESS;

  post () {
    this.isFetching = true

    this.$emit('fetch', { isFetching: this.isFetching, data: null })

    const headers = {
      Authorization: 'Bearer ' + window.token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    fetch(this.url, { headers: headers, method: 'POST', body: JSON.stringify(this.body) })
      .then(response => {
        this.status = (response.status === 200) ? HttpPost.SUCCESS : HttpPost.FAILED
        this.isFetching = false

        this.$emit('fetch', { isFetching: this.isFetching, data: null })

        return response.json()
      })
      .then(data => {
        this.$emit('fetch', { isFetching: this.isFetching, data: data })
      })
  }
}
