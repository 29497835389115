
























































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Functions} from "@/mixins/Functions";
import DataTable from "@/mave-ui/components/data/DataTable.vue";
import DataTableColumn from "@/mave-ui/components/data/DataTableColumn.vue";
import HttpGet from "@/mave-ui/components/logic/HttpGet.vue";
import Card from "@/mave-ui/components/Card.vue"
import Button from "@/mave-ui/components/form/Button.vue";
import Modal from "@/mave-ui/components/ui/modal/Modal.vue";
import Badge from "@/mave-ui/components/Badge.vue";
import StatusChange from "@/components/StatusChange.vue";
import Searchbox from "@/mave-ui/components/form/Searchbox.vue";
import Dropdown from "@/mave-ui/components/form/Dropdown.vue";
import BaseLayout from "@/layouts/BaseLayout.vue";
import HttpPost from "@/mave-ui/components/logic/HttpPost.vue";

@Component({
  components: {
    HttpPost,
    BaseLayout,
    Dropdown, Searchbox, StatusChange, HttpGet, DataTableColumn, DataTable, Card, Button, Modal, Badge
  },
  mixins: [Functions]
})
export default class Home extends Vue {
  $refs!: {
    voices: HttpGet;
    table: DataTable;
    change: StatusChange;
  }

  playing = {
    url: '',
    name: '',
  };
  isPlaying = false;

  voiceDelete = {
    id: 0,
    name: ''
  };
  currentPage = 1;
  search = {sort: ''}
  changes = 0

  play(row: any) {
    this.isPlaying = true;
    this.playing = {
      url: row.file,
      name: row.product + ' - ' + row.geo
    }
  }

  sort(sortBy: string) {
    if (sortBy === 'date') {
      sortBy = 'created_at'
    }

    this.search.sort = sortBy;
    this.handleSearch();
  }

  paginate(page: number) {
    this.currentPage = page
    this.changes++;
  }

  // @Watch('search')
  handleSearch() {
    this.currentPage = 1;
    this.$refs.table.$forceUpdate()
    this.$refs.voices.$forceUpdate()
    this.$refs.change.$forceUpdate()
  }

  voiceDeleted() {
    this.$nextTick().then(() => {
      this.voiceDelete = {id: 0, name: ''}
      this.changes++
      this.$refs.voices.fetch();
    })
  }


}
